import React, {useEffect, useState} from "react";
import DOMPurify from "dompurify";
import {Button, Container, Form, Input, InputGroup} from "reactstrap";

const Add = () => {
    const [submitMessage, setSubmitMessage] = useState('');
    const [showForm, setShowForm] = useState(true);

    const handleLoaded = _ => {window.grecaptcha.ready(_ => {window.grecaptcha.execute("6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS", {action: "submit"})})}

    useEffect(() => {
        // 1. Create and Add the Script
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS";
        script.async = true; // Load script asynchronously for better performance
        document.body.appendChild(script);
        script.addEventListener("load", handleLoaded);

        // 3. Clean Up on Unmount
        return () => {
            script.removeEventListener("load", handleLoaded); // Remove the event listener
            document.body.removeChild(script); // Remove the script element

            // Remove reCAPTCHA Badge if it exists
            const badge = document.querySelector('.grecaptcha-badge');
            if (badge) {
                badge.parentNode.removeChild(badge);
            }
        };
    }, []); // Empty dependency array ensures this runs only once (on mount)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS", {action: "add"})
                .then(async token => {
                    try {
                        const myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        const raw = JSON.stringify({url: DOMPurify.sanitize(formData.get("url")), token: token});

                        const requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        const response = await fetch(process.env.REACT_APP_API_URL +'add/', requestOptions);

                        const data = await response.json();
                        const obj = JSON.parse(data);

                        if (obj.response === "") {
                            setShowForm(false)
                            setSubmitMessage("Your request has been submitted and will be reviewed soon. We will add it shortly after approval. Thank you!");
                            return
                        }
                        setSubmitMessage(obj.response);

                    } catch (error) {
                        setSubmitMessage("Error submitting form please try again or latter.");
                        console.error('Error submitting form data:', error);
                    }
                })
        })
    };

    return (
        <Container className="my-5">
            {showForm &&
                <h4 className="text-center">Add new AI tool in our record</h4>
            }
            {submitMessage && <p className="alert alert-info small">{submitMessage}</p>}
            {showForm &&
                <Form onSubmit={handleSubmit}>
                    <InputGroup>
                        <Input
                            type="text"
                            autoFocus
                            name="url"
                            placeholder="url"/>
                        <div
                            className="g-recaptcha"
                            data-sitekey="6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS"
                            data-size="invisible"
                        ></div>
                        <Button type="submit" color="primary">Submit</Button>
                    </InputGroup>
                </Form>
            }
        </Container>
    );
}
export default Add;