import DOMPurify from "dompurify";
import {Link} from "react-router-dom";
import React from "react";
import useRecaptchaForm from './useRecaptchaForm';
import {Button, Container} from "reactstrap";


const About = () => {

    const getFormData = (formElement) => {
        const name = formElement.querySelector('input[name="name"]').value.trim();
        const email = formElement.querySelector('input[name="email"]').value.trim();
        const message = formElement.querySelector('textarea[name="message"]').value.trim();

        if (!name || !email || !message) {
            throw new Error('Please fill out all required fields.');
        }

        // Basic email validation (you can add more robust checks)
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            throw new Error('Please enter a valid email address.');
        }

        return {
            name: DOMPurify.sanitize(name),
            email: DOMPurify.sanitize(email),
            message: DOMPurify.sanitize(message),
        };
    };

    const {submitMessage, showForm, handleSubmit} = useRecaptchaForm(
            process.env.REACT_APP_API_URL + 'contact/',
        'Thank you for your message!',
        getFormData
    );

    return (
        <Container className="my-5">
            <h1>About AiPick</h1>
            <p>AiPick is a powerful search engine designed to help people easily find AI-related information available in
                the public domain. With its intuitive interface and advanced algorithms, AiPick can quickly and accurately
                search through a vast database of resources to deliver the most relevant results for your search
                topic.</p>

            <p>Whether you're a researcher, student, or simply someone looking to learn more about artificial
                intelligence, AiPick provides a one-stop-shop for all your information needs. Simply enter your search
                query and let AiPick do the rest. With its cutting-edge technology and vast network of resources, AiPick is
                the ultimate AI search engine for the modern age.</p>

            <p>So why wait? Try <Link to="/">AiPick</Link> today and discover the power of AI at your fingertips!</p>

            <p>
                Got feedback? Suggestions? Comments? Don't be shy – we're all ears! 👂
            </p>
            {showForm && (
                <form onSubmit={handleSubmit}>
                    <div className="col-6">
                        <p><input type="text" className="form-control" id="fullname" name="name"
                                  placeholder="Name" required/>
                        </p>
                        <p><input type="text" className="form-control" id="email" name="email"
                                  placeholder="email@email.com" required/>
                        </p>
                        <p><textarea className="form-control col-12" name="message" placeholder="Your Message"
                                     required></textarea>
                        </p>
                        <div
                            className="g-recaptcha"
                            data-sitekey="6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS"
                            data-size="invisible"
                        ></div>
                    </div>

                    <p><Button type="submit" color="primary">Submit</Button></p>
                </form>
            )}
            {submitMessage && <p className="alert alert-info small">{submitMessage}</p>}
        </Container>
    );
}
export default About;