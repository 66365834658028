export async function fetchSearchResults(query, currentPage) {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            search: query,
            page: currentPage,       // Add page information
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        const response = await fetch(process.env.REACT_APP_API_URL + 'search/', requestOptions);

        if (!response.ok) {
            // Throw a custom error object containing more details
            const errorDetails = {
                message: `HTTP error! status: ${response.status}`,
                status: response.status,
                is404: response.status === 404
            };
            throw errorDetails;
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw error; // Re-throw the error for the calling component to handle
    }
}

export function parseTime(string) {
    const microSec = parseFloat(string);
    let result = (microSec / 1000000).toFixed(2);
    if (result < 0.01) {
        result = 0.01;
    }
    return result;
}
