// Login component
import {useState} from "react";
import {Container} from "reactstrap";

const Login = ({setAuth}) => { // setAuth is a function that updates the auth state
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        // Make API call to authenticate user
        fetch(process.env.REACT_APP_API_URL + "login/", {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify({password}),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                // If authentication is successful, set auth state to true
                if (data.isValid) {
                    sessionStorage.setItem("auth", "true");
                    setAuth(true);
                } else {
                    alert("Incorrect password, please try again.");
                }
            });
    };

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <h2>Login</h2>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </label>
                <button type="submit">Login</button>
            </form>
        </Container>
    );
}
export default Login;