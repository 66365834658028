import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Form, Input, InputGroup} from "reactstrap";
import DOMPurify from "dompurify";

const AdminPage = () => {
    const [url, setUrl] = useState('');
    const [urlEncoded, setUrlEncoded] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState(null);
    const formRef = useRef(null);
    const [pendingRequestsMessage, setPendingRequestsMessage] = useState('');

    useEffect(() => {

        // Fetch pending requests data
        fetch(process.env.REACT_APP_API_URL +"u/pending/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                return response.json();
            })
            .then(data => {
                // Set pendingRequestsMessage based on the response data
                if (data.count === 0) {
                    setPendingRequestsMessage("");
                    return;
                }
                setPendingRequestsMessage(data.count + " Pending requests");
            })
            .catch(error => {
                console.error("Error fetching pending requests:", error);
                setPendingRequestsMessage("Error fetching pending requests.");
            });
    }, []); // Empty dependency array ensures the effect runs only once after initial render

    const fetchMetadata = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({url: DOMPurify.sanitize(urlEncoded)});

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            credentials: "include",
            body: raw

        };

        fetch(process.env.REACT_APP_API_URL + `u/metadata/`, requestOptions)
            .then((response) => {

                if (response.status === 401) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                if (!response.ok) {
                    return;
                }
                return response.json();
            })
            .then((data) => {
                const rd = JSON.parse(data);
                setFormData(rd);
            })
            .catch(error => {
                console.log("error", error);
            });
    };

    async function handleInsertSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        const payload = {};
        data.forEach((value, key) => {
            payload[key] = value;
        });
        payload["url"] = urlEncoded;
        const response = await fetch(process.env.REACT_APP_API_URL + "u/insert/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(payload),
        }).then((response) => {
            if (response.status === 401) {
                sessionStorage.clear();
                window.location.reload();
            }
            return response.json();
        }).catch(error => {
            console.log("error", error);
            alert("Error inserting record : " + error);

        })
        if (response !== `{"response": ""}`) {
            alert("Error  : " + response);
            return;
        }
        console.log("record added successfully");

        setFormData(null);
        setUrl('');
    }

    async function handleLogoutSubmit(event) {
        event.preventDefault();
        const response = await fetch(process.env.REACT_APP_API_URL + "logout/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });
        await response.json();
        sessionStorage.clear();
        navigate(`/`)
    }

    function handleLoadRequest() {
        //make fetch request to load 1 pending request
        setFormData(null);
        setUrl('');
        setUrlEncoded('');

        fetch(process.env.REACT_APP_API_URL + "u/load_request/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                return response.json();
            })
            .then(data => {
                // Set pendingRequestsMessage based on the response data
                const rd = JSON.parse(data);
                setFormData(rd);
                setUrl(rd.URL);
                setUrlEncoded(encodeURIComponent(rd.URL));

            })
            .catch(error => {
                console.error("Error fetching pending requests:", error);
            });
    }

    return (
        <div>
            <div className="d-flex justify-content-end">
                <hr/>
                {pendingRequestsMessage && <Button onClick={handleLoadRequest} type="button">Load Request</Button>}
                <Button onClick={handleLogoutSubmit} type="button">Logout</Button>

            </div>
            <hr className="clearfix"/>
            {pendingRequestsMessage && <p className="alert alert-info small">{pendingRequestsMessage}</p>}
            <h1>Insert new record</h1>
            <Form ref={formRef} onSubmit={handleInsertSubmit}>
                <InputGroup>
                    <Input
                        type="text"
                        autoFocus
                        name="url"
                        value={url}
                        defaultValue={formData?.URL}
                        onChange={(e) => {
                            setUrl(e.target.value);
                            setUrlEncoded(encodeURIComponent(e.target.value));
                        }}
                        placeholder="URL"/>
                    <Button color="primary" onClick={fetchMetadata}>Fetch</Button>
                </InputGroup>
                <Input
                    type="text"
                    name="name"
                    defaultValue={formData?.Title}
                    placeholder="Name"/>
                <Input
                    type="text"
                    name="title"
                    defaultValue={formData?.Title}
                    placeholder="Title"/>
                <Input
                    type="text"
                    name="description"
                    defaultValue={formData?.Description}
                    placeholder="Description"/>
                <Input
                    type="text"
                    name="fav_icon"
                    defaultValue={formData?.FaviconURL}
                    placeholder="fav icon url"/>
                <Input
                    type="text"
                    name="rating"
                    defaultValue={formData?.Rating}
                    placeholder="rating"/>
                <Input
                    type="text"
                    name="tags"
                    defaultValue={formData?.Keywords}
                    placeholder="tag1, tag2"/>
                <Input
                    type="text"
                    readOnly={true}
                    defaultValue={formData?.ID}
                    name="id"
                />
                <Button color="primary" type="submit">Insert</Button>
            </Form>

        </div>
    );
}
export default AdminPage;