import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Container, Media} from "reactstrap";
import {fetchSearchResults} from './SearchUtils';

const SearchResult = (props) => {
    // const query = decodeURIComponent(useParams().query);
    const [searchParams] = useSearchParams();
    console.log(searchParams)
    const query = searchParams.get('q');
    const [prevQuery, setPrevQuery] = useState(query); // Store previous query
    const [results, setResults] = useState({products: [], totalResults: 0, totalPages: 0, searchTime: "0.0"});
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errorDetails, setErrorDetails] = useState({message: '', status: 0, is404: false})
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [autoLoadsRemaining, setAutoLoadsRemaining] = useState(10); // Number of autoloads remaining
    const [hasMoreResults, setHasMoreResults] = useState(true); // Track if more results are available
    const [observerEnabled, setObserverEnabled] = useState(false); // New state to control observer
    const bottomBoundaryRef = useRef(null);


    const handleReloadClick = () => {
        navigate('/', {replace: true}); // Navigate to the same route, replacing history
    };

    const handleLoadMoreClick = () => {
        setCurrentPage(currentPage + 1);
    };


    useEffect(() => {
        if (query !== prevQuery) {
            // Reset state on new search but don't fetch yet
            setResults({products: [], totalResults: 0, totalPages: 0, searchTime: "0.0"});
            setAutoLoadsRemaining(10);
            setHasMoreResults(true);
            setObserverEnabled(false); // Disable observer initially
            setPrevQuery(query); // Update prevQuery
        }
        // Fetch data when query changes or when currentPage changes AND it's NOT the first page
        //&& query !== prevQuery
        if (query !== '') {
            setCurrentPage(1);  // Reset currentPage to 1 only when the query changes.
            fetchData(query, 1, setIsLoading, setResults, setHasMoreResults, setObserverEnabled, setIsError); // Fetch with the reset currentPage
        }
    }, [query]);

    useEffect(() => {
        if (currentPage > 1) {
            fetchData(query, currentPage, setIsLoading, setResults, setHasMoreResults, setObserverEnabled, setIsError);
        }
    }, [currentPage]);

    async function fetchData(query, currentPage, setIsLoading, setResults, setHasMoreResults, setObserverEnabled, setIsError) {
        setIsLoading(true);
        try {
            const encodedQuery = encodeURIComponent(query);
            const data = await fetchSearchResults(encodedQuery, currentPage);

            if (data.products && data.products.length > 0) {
                setResults((prevResults) => ({
                    products: [...prevResults.products, ...data.products],
                    totalResults: data.totalResults,
                    totalPages: data.totalPages,
                    searchTime: data.searchTime,
                }));

                if (data.totalPages > currentPage) {
                    setObserverEnabled(true);
                } else {
                    setObserverEnabled(false);
                }
            } else {
                setHasMoreResults(false);
                setAutoLoadsRemaining(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorDetails(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (!observerEnabled) {
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && autoLoadsRemaining > 0 && !isLoading && hasMoreResults) {
                    setAutoLoadsRemaining((prevAutoLoadsRemaining) => prevAutoLoadsRemaining - 1);
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            },
            {
                root: null,
                rootMargin: "20px",
                threshold: 0.8,
            }
        );

        // Move these lines INSIDE the useEffect
        if (bottomBoundaryRef.current) {
            observer.observe(bottomBoundaryRef.current);
        }

        return () => {
            if (bottomBoundaryRef.current) {
                observer.unobserve(bottomBoundaryRef.current);
            }
        };

    }, [autoLoadsRemaining, isLoading, hasMoreResults, currentPage, observerEnabled]);


    if (query === '') {
        return (
            <Container className="offset-1">
                <h2>Please enter a search query</h2>
            </Container>
        );
    }


    if (isError) {
        if (errorDetails.is404) {
            return (<Container className="offset-1">
                    <h4>No result found</h4>
                </Container>
            );
        } else return (
            <Container className="offset-1">
                <h4>Something went wrong, Please try again or latter.</h4>
                <p>
                    <button onClick={handleReloadClick}>Reload</button>
                </p>
            </Container>
        );
    }

    function parseTime(string) {
        var microSec = parseFloat(string)
        let result = (microSec / 1000).toFixed(2)
        if (result < 0.01) {
            result = 0.01
        }
        return result
    }


    if (results.products && Array.isArray(results.products)) {
        return (
            <Container key={query} className="scroll offset-1">
                <p className="fw-light">
                    About {results.totalResults} results (
                    {parseTime(results.searchTime)}{" "} seconds)
                </p>
                {results.products.map((result) => (
                    <SearchResultComp
                        // key={result.id}
                        image={result.fav_icon}
                        name={result.name}
                        url={result.url}
                        title={result.title}
                        description={result.description}
                    />
                ))}
                {results.products.length === 0 && !isLoading && (<h4>No result found</h4>)}
                {/* WORKING PAGINATION COMMENTED IN FAVOR OF INFINITE SCROOL */}
                {/*<Pagination*/}
                {/*    totalResults={results.totalResults}*/}
                {/*    totalPages={results.totalPages}*/}
                {/*    currentPage={results.currentPage}*/}
                {/*    onPageChange={onPageChange}*/}
                {/*/>*/}
                <div ref={bottomBoundaryRef} style={{height: "1px"}}></div>

                {isLoading && (
                    <Container className="offset-1">
                        <h4>Loading...</h4>
                    </Container>
                )}

                {/* Conditional "Load More" Button */}
                {autoLoadsRemaining === 0 && !isLoading && hasMoreResults && ( // Only show button when there are more results
                    <button onClick={handleLoadMoreClick}>Load More</button>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <h2>No result found</h2>
        </Container>
    );
}

const SearchResultComp = ({image, name, url, title, description}) => {
    return (
        <Media className="mb-3 shadow-sm p-1 mb-1 bg-white rounded">
            <Media left className="float-left d-flex align-items-center">
                <a href={url} target="_blank" rel="noreferrer">
                    <Media object src={image} alt={name} height="32" width="32" className="rounded-circle me-1"/>
                </a>
                <div>
                    <Media href={url} target="_blank" rel="noreferrer"
                           className="mb-0 d-block text-decoration-none">{name}</Media>
                    <Media href={url} className="text-decoration-none  text-muted small" target="_blank"
                           rel="noreferrer">{url}</Media>
                </div>
            </Media>
            <Media body className="mt-2">
                <h6><a href={url} target="_blank" rel="noreferrer"
                       className="text-decoration-none  link-dark">{title}</a></h6>
                <p>{description}</p>
            </Media>
        </Media>
    );
};
export default SearchResult;