import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

function useRecaptchaForm(apiEndpoint, successMessage, getFormData) {
    const [submitMessage, setSubmitMessage] = useState('');
    const [showForm, setShowForm] = useState(true);
    const siteKey = "6LdE8eopAAAAAJ9rLfBMvT59A7AJCrvVKLtg_sRS";

    const handleLoaded = () => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(siteKey, { action: 'submit' });
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', handleLoaded);

        return () => {
            script.removeEventListener('load', handleLoaded);
            document.body.removeChild(script);
            const badge = document.querySelector('.grecaptcha-badge');
            if (badge) {
                badge.parentNode.removeChild(badge);
            }
        };
    }, [siteKey]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitMessage('');

        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(siteKey, { action: 'submit' });
                const formData = getFormData(event.target);

                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...formData,
                        token,
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    setShowForm(false);
                    setSubmitMessage(successMessage);
                } else {
                    setSubmitMessage(data.error || 'An error occurred while submitting the form.');
                }
            } catch (error) {
                setSubmitMessage('An error occurred. Please try again later.');
                console.error('Error submitting form data:', error);
            }
        });
    };

    return { submitMessage, showForm, handleSubmit };
}

export default useRecaptchaForm;