import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import DOMPurify from "dompurify";
import {Button, Col, Container, Form, Input, InputGroup, Row} from "reactstrap";
import {Context} from "./App";

const Home = () => {
    const {query, setQuery} = useContext(Context);
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const q = encodeURIComponent(formData.get('q'));
        if (q.trim() !== '') {
            setQuery(DOMPurify.sanitize(formData.get('q')))
            navigate(`/search?q=${q}`);
        }
    }

    return (
        <Container>
            <Row style={{marginTop: '5%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Col sm="12" className="text-center">
                    <h1>AiPick</h1>
                </Col>
            </Row>
            <Row>
                <Col sm="6" className="offset-sm-3">
                    <Form onSubmit={handleSubmit}>
                        <InputGroup>
                            <Input
                                type="text"
                                autoFocus
                                name="q"
                                placeholder="Search"/>
                            <Button type="submit" color="primary">Search</Button>
                        </InputGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}
export default Home;