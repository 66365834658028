import React, {useContext, useState} from "react";
import {Link, matchPath, useLocation, useNavigate} from "react-router-dom";
import DOMPurify from "dompurify";
import {
    Button,
    Collapse,
    Form,
    Input,
    InputGroup,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink
} from "reactstrap";
import {Context} from "./App";

const NavigationBar = (props) => {
        const {query, setQuery} = useContext(Context);
        const [isOpen, setIsOpen] = useState(false);
        const navigate = useNavigate();
    const showSearchBar = matchPath({path: "/search", exact: false}, useLocation().pathname,);

        function handleSubmit(event) {
            event.preventDefault();
            const formData = new FormData(event.target);
            const q = encodeURIComponent(formData.get('q'));
            if (q.trim() !== '') {
                setQuery(DOMPurify.sanitize(formData.get('q')))
                navigate(`/search?q=${q}`);
            }
        }

    const handleAddClick = () => {
        navigate('/'); // Navigate away to trigger a remount on the next navigation to '/add'
        setTimeout(() => { // Introduce a slight delay (optional)
            navigate('/add', {replace: true});
        }, 10);
    };

    return (
            <Navbar color="light" light expand="md" fixed="top">
                <NavbarBrand className="offset-sm-1" tag={Link} to="/">AiPick</NavbarBrand>
                {showSearchBar && (
                    <Form className="mr-auto flex-grow-1" onSubmit={handleSubmit}>
                        <InputGroup style={{width: "45%", marginLeft: "10px"}}>
                            <Input
                                name="q"
                                autoFocus
                                type="text"
                                placeholder="Search"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <Button color="primary" type="submit">Search</Button>
                        </InputGroup>
                    </Form>
                )}
                <Nav className="ml-auto" navbar>
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)}/>
                    <Collapse isOpen={isOpen} navbar>
                        <NavItem>
                            <NavLink tag={Link} to="/about">About</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className=" ml-auto" onClick={handleAddClick}><Button className="btn-primary">Add
                                new</Button></NavLink>
                        </NavItem>
                    </Collapse>
                </Nav>
            </Navbar>
        );
}
export default NavigationBar;