import React, {useEffect, useState} from 'react';
import SearchResult from './SearchResult';
import Add from './Add';
import About from './About';
import NavigationBar from './NavigationBar';
import Home from './Home';
import Login from './Login';
import AdminPage from './AdminPage';

import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {Container, Navbar} from 'reactstrap';
import './App.css'

// create a context for the shared variable
export const Context = React.createContext();

function Aiii() {
    // define the shared variable in the top-level component
    const [query, setQuery] = useState('');
    const [auth, setAuth] = useState(false);

    // Check localStorage on app load to see if user is already authenticated
    useEffect(() => {
        const storedAuth = sessionStorage.getItem("auth");
        if (storedAuth === "true") {
            setAuth(true);
        }
    }, []);

    // Update localStorage when auth state changes
    useEffect(() => {
        localStorage.setItem("auth", auth);
    }, [auth]);

    return (
        <Context.Provider value={{query, setQuery}}>
            <Router>
                <NavigationBar/>
                <Container fluid>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/search" element={<SearchResult/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/add" element={<Add/>}/>
                        <Route
                            path="/login"
                            element={
                                auth ? <Navigate to="/insert"/> : <Login setAuth={setAuth}/>
                            }
                        />
                        <Route
                            path="/insert"
                            element={auth ? <AdminPage/> : <Navigate to="/login"/>}
                        />
                    </Routes>
                </Container>
                <Navbar color="light" fixed="bottom">
                    <Container>
                        <p className="mb-0 text-center">© 2024 AiPick. All rights reserved.</p>
                    </Container>
                </Navbar>
            </Router>
        </Context.Provider>
    );
}
export default Aiii;